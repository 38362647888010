import { React, useRef, useState, useEffect } from 'react';
import axios from 'axios';
import './results.css';

function Result() {
  const [candidateNo, setCandidateNo] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [params, setParams] = useState('');

  const [errorMassages, setErrorMassages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [candidate, setCandidate] = useState('');
  const candidateRef = useRef();

  useEffect(() => {
    candidateRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMassages([]);
  }, [candidateNo, dateOfBirth, candidate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      var dataForm = new FormData();
      dataForm.append('candidateNo', candidateNo);
      dataForm.append('date_of_birth', dateOfBirth);
      const response = await axios.request({
        method: 'POST',
        url: `${process.env.REACT_APP_API_RESULT_URL}`,
        data: dataForm,
        mode: 'no-cors',
      });
      console.log(response.data);
      if (response.data.hasOwnProperty('data')) {
        const data = await response.data;
        console.log(data);
        setSuccess(true);
        setCandidate(data.data);
        const queryParams = {
          candidate_no: candidateNo,
          token: data.token,
        };

        const uRLSearchParams = new URLSearchParams(queryParams);
        setParams(process.env.REACT_APP_API_RESULT_URL + '?' + uRLSearchParams);
      } else {
        setErrorMassages(response.data);
      }
      // setErrorMassages()
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="results">
      {/* <!-- inner page banner --> */}
      <div
        className="main-banner"
        style={{
          background: 'url(./images/banner/banner1.png) center top',
        }}
      >
        <div className="container px-md-0">
          <h2>
            <span>Individual Results</span>
          </h2>
        </div>
      </div>
      <div className="breadcrumb">
        <div className="container px-md-0">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a href="/home">Home</a>
            </li>
            <li className="list-inline-item active">Individual Results</li>
          </ul>
        </div>
      </div>
      {/* <!-- inner page banner --> */}
      <div className="container px-md-0 main-container">
        <p>
          Academic performance is the term that indicates a student's
          achievement after completing a course or subject from LGCSE or Grade
          7. It measures students' learning across various academic subjects,
          which is assessed by formative and summative assessments.
        </p>
        <div className="sec-title text-center">
          <span className="decor">
            <span className="inner" />
          </span>
        </div>
        <p></p>
        {success ? (
          <div className="individual text-center">
            <div className="card dashboard">
              <h5 className="card-header">
                {candidate[0].name} {candidate[0].surname}
              </h5>
              <div className="card-body">
                <h5 className="card-title">Successfully logged in</h5>
                <iframe src={params} width="80%" height="1000px"></iframe>
              </div>
            </div>
          </div>
        ) : (
          <form
            action={process.env.REACT_APP_API_RESULT_URL}
            className="printIn"
            id="individual-result"
            method="post"
            onSubmit={handleSubmit}
          >
            <input
              type="hidden"
              name="school_csrf_name"
              defaultValue="c1c234eb5ad4b21fee2c7d5ce4b7e026"
            />
            <div className="box2 form-box">
              <i className="text-danger">
                <strong>Please use correct ID Number and Date of Birth</strong>
              </i>
              <h2></h2>
              <i className="text-danger">
                <div className="errors">
                  {errorMassages.errors
                    ? errorMassages.errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))
                    : ''}
                </div>
              </i>
              <div className="row">
                <div className="col-md-4 mb-sm">
                  <div className="form-group">
                    <label className="control-label">
                      {' '}
                      Exam type <span className="required">*</span>
                    </label>
                    <select
                      name="exam_id"
                      className="form-control select2-hidden-accessible"
                      data-plugin-selecttwo=""
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option value="" selected="selected">
                        Select
                      </option>
                      <option value={1}>LGCSE</option>
                      <option value={2}>Grade 7</option>
                    </select>
                    <span
                      className="select2 select2-container select2-container--default"
                      dir="ltr"
                      style={{ width: '100%' }}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single"
                          role="comboox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabIndex={0}
                          aria-labelledby="select2-exam_id-cy-container"
                        >
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation" />
                          </span>
                        </span>
                      </span>
                      <span className="dropdown-wrapper" aria-hidden="true" />
                    </span>
                    <span className="error" />
                  </div>
                </div>
                <div className="col-md-4 mb-sm">
                  <div className="form-group">
                    <label className="control-label">
                      {' '}
                      Candidate Number <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="candidateNo"
                      ref={candidateRef}
                      className="form-control"
                      onChange={(e) => setCandidateNo(e.target.value)}
                      value={candidateNo}
                    />
                    <span className="error" />
                  </div>
                </div>
                <div className="col-md-4 mb-sm">
                  <div className="form-group">
                    <label className="control-label">
                      {' '}
                      Date of Birth <span className="required">*</span>
                    </label>
                    <input
                      type="date"
                      name="date_of_birth"
                      className="form-control"
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      value={dateOfBirth}
                    />
                    <span className="error" />
                  </div>
                </div>
              </div>
             
              <button
                type="submit"
                className="btn"
                data-loading-text="<i class='fas fa-spinner fa-spin'></i> Processing"
                id="view"
                name="view"
              >
                <i className="fas fa-plus-circle" /> Submit
              </button>
            </div>
          </form>
        )}
        <div className="row">
          <div className="col-md-12">
            <div id="card_holder" style={{ display: 'none' }}>
              <div className="box2 form-box">
                <button type="button" className="btn btn-1" id="print">
                  <i className="fa fa-print" /> Print
                </button>
                <div id="card" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Result;
